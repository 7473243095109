import 'normalize.css/normalize.css'
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'
import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import router from './router'
import store from './store'
import './util/rem'
import axios from 'axios'
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
Vue.use(mavonEditor)

Vue.config.productionTip = false
Vue.prototype.$ajax = axios
window.scrollTo(0,0)
router.afterEach((to,from,next)=>{window,scrollTo(0,0)})
new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
