<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'App',
  // provide () {
  //   return {
  //     reload: this.reload
  //   }
  // },
  data () {
    return {
      // isRouterAlive: true,
      ismobile: false
    }
  },
  created () {
    const that = this
    if (localStorage.getItem('setLoginStatus')) {
      if (localStorage.getItem('setLoginStatus')) {
        const status = localStorage.getItem('setLoginStatus')
        this.$store.commit('setLoginStatus', status)
      }
      if (localStorage.getItem('usermessage')) {
        const user = JSON.parse(localStorage.getItem('usermessage'))
        const userid = user.id
        this.$store.commit('setUserid', userid)
      }
    }
    if (localStorage.getItem('ispro')) {
      const status = localStorage.getItem('ispro')
      this.$store.commit('setispro', status)
    }
    if (localStorage.getItem('navActive')) {
      const navActive = localStorage.getItem('navActive')
      this.$store.commit('setnavActive', navActive)
    }
    // eslint-disable-next-line no-unused-vars
    const jump = setInterval(() => {
      that._isMobile()
    }, 10)
  },
  mounted () {
    // 友盟统计添加
    const script = document.createElement('script')
    script.src =
      'https://s9.cnzz.com/z_stat.php?id=1280209971&web_id=1280209971' // 该路径为点击统计代码后，页面中第一个 文字统计 script代码
    script.language = 'JavaScript'
    document.body.appendChild(script)
  },
  watch: {
    $route () {
      if (window._czc) {
        const location = window.location
        const contentUrl = location.pathname + location.hash
        const refererUrl = '/'
        console.log('添加前', window._czc)
        window._czc.push(['_trackPageview', contentUrl, refererUrl])
        console.log('添加后', window._czc)
      }
    }
  },

  methods: {
    _isMobile () {
      const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      this.$data.ismobile = flag
      if (flag) {
        window.location.replace('https://keyanpro.com/kyrh/#/')
        // eslint-disable-next-line no-undef
        window.clearInterval(jump)
      }
      return flag
    }
    // reload () {
    //   this.isRouterAlive = false
    //   this.$nextTick(function () {
    //     this.isRouterAlive = true
    //   })
    // }
  }
}
</script>
<style>
.el-message {
  font-size: 16px;
}
</style>
<style lang="scss">
#app {
  font-size: 12px;
}
::-webkit-scrollbar {
  width: 9px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #5e068c;
  border-radius: 21px;
  width: 20px;
  height: 63px;
}
::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 7px;
}
.el-message__content {
  font-size: 20px !important;
}
.el-message {
  font-size: 20px !important;
}
</style>
